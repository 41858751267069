import { settingsService } from "./settingsService";
import axios, { AxiosRequestConfig, AxiosInstance } from "axios";


const createInstance = async (): Promise<AxiosInstance> => {
    await settingsService.init();
    const appConfig = settingsService.getConfig();
    console.log(appConfig);
    const config: AxiosRequestConfig = {
        headers: {
            Authorization: "Bearer " + "user.access_token",
            Pragma: 'no-cache',
            'Cache-Control': 'no-cache',
        },
        baseURL: `${appConfig.apiUrl}/api`,
        withCredentials: false
    };

    const instance = axios.create(config);
    return instance;
}

const getInstance = async (): Promise<AxiosInstance> => {
    return createInstance();
};

export default { getInstance }