import logo from './logo.svg';
import './App.css';
import httpClient from './httpClient';
import { useState } from 'react';

const Get = async()=>
{
  var client = await httpClient.getInstance();
  console.log(client);
  var respunse = await client.get("AppFirst/me")
  return respunse.data;
}
interface me{
  id?:string
}
function App() {
  const [value, setvalue] = useState<me>({});


  return (
    <div className="App" onClick={async ()=>{ setvalue(await Get()) }}>
      <header className="App-header">
        <div>
          {value.id}
        </div>
        <img src={logo}  className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
